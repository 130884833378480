<template>
    <div style="height: 100%">
        <div class="row">
            <div class="col-12" v-if="error">
                {{error}}
            </div>
            <div class="col-12">
                <h5 class="text-center mb-2 mt-4 text-uppercase">Scan Location</h5>
                <input type="text" ref="scan_result" placeholder="location"
                       class="form-control"
                       v-model="scan_result"
                       v-on:keyup.enter="submit"
                       onpaste="return false;"
                       ondrop="return false;"
                       autofocus/>
            </div>
        </div>
        <div class="row text-center mt-3 mb-5">
            <div class="col-6">
                <button class="button btn btn-secondary btn-block" type="button" @click="submit" :disabled="loading">
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                    {{loading ? 'loading' : 'SUBMIT'}}
                </button>
            </div>
            <div class="col-6">
                <button class="button btn btn-danger btn-block" type="button" @click="clear" :disabled="loading">CLEAR</button>
            </div>
        </div>
    </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

export default {
    name: "scan_location",
    props: {
        waveId: String,
    },
    data() {
        return {
            scan_result: "",
            camera_title: 'Camera On',
            camera: 'off',
            error: null,
            loading:false,
        }
    },
    mounted() {
        this.$refs.scan_result.focus();
    },
    methods: {
        preventPaste(){
            this.scan_result = ""
        },
        onDecode (decodedString) {
            this.scan_result=decodedString.trim();
        },
        clickCamera(){
            if(this.camera==='off'){
                this.camera = 'auto';
                this.camera_title= 'Camera Off';
            }else{
                this.camera = 'off';
                this.camera_title= 'Camera On'
            }
        },
        async onInitCamera (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        },
        submit() {
            if(!this.scan_result) {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'location required',
                });
                return;
            }
            // check location match or not
            // check location with api

            axios.post(`task-counters/${this.waveId}/check-location`,{
               'location':this.scan_result
            }).then(({data})=>{
                const task = data.data;
                var location = encodeURIComponent(task.location);
                window.location= `/counter/stocks/${this.waveId}/${task.id}/${location}/item`;
            }).catch(err=>{
                this.$refs.scan_result.focus();
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'location not valid',
                    timer: 2000
                });
                Sentry.captureException(err);
                this.clear();
            })
            this.$refs.scan_result.focus();
        },
        clear() {
            this.scan_result = '';
        },
    }
}
</script>

<style scoped>

</style>
