<template>
    <div>
        <div class="row pt-4">
            <div class="col-12">
                <h3 class="font-weight-bold mb-4 pb-2">TASK COUNTER</h3>
            </div>
            <div class="col-12">
                <nav aria-label="breadcrumb" class="bg-white">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" aria-current="page">
                            <a :href="`/counter/waves`">Home</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            <a :href="`/counter/tasks/${waveId}/list`">{{wave.wave_name}}</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            <a :href="`/counter/stocks/${waveId}/${taskId}/${location}/item`">{{location}}</a>
                        </li>
                        <li class="breadcrumb-item text-uppercase active" aria-current="page">
                            Scan Full Box
                        </li>
                    </ol>
                </nav>
            </div>

        </div>  <!-- ----/row------ -->
        <div class="row">
            <div style="height: 100%">
                <div class="row">
                    <div class="col-12 mt-2 mb-2" v-if="message">
                        <div class="alert alert-success alert-block">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            <strong>{{ message }}</strong>
                        </div>
                    </div>
                    <div class="col-12" v-if="error">
                        {{error}}
                    </div>
                    <div class="col-12">
                        <h5 class="mb-2 mt-4 text-uppercase">Box ID</h5>
                        <input type="text" name="scan_result"
                               placeholder="BOX ID" class="form-control" v-model="box_id" autofocus/>
                    </div>
                    <div class="col-10">
                        <h5 class="mb-2 mt-4 text-uppercase">LPN</h5>
                        <input type="text" name="scan_result" placeholder="LPN" class="form-control" v-model="lpn"/>
                    </div>
                    <div class="col-2">
                        <h5 class="text-center mb-2 mt-4 text-uppercase">&nbsp;</h5>
                        <button type="button" :class="stateActiveCamera===1 ? 'btn btn-primary' : 'btn btn-secondary'" @click="clickCamera(1)" :disabled="loading">
                            <i class="fas fa-camera"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h5 class="mb-2 mt-4 text-uppercase">QTY</h5>
                        <input type="number" ref="input_qty" class="form-control" v-model="qty_input" v-on:keyup.enter="submit"/>
                    </div>
                    <div class="col-12">
                        <h3 class="mb-2 mt-4 text-uppercase">UOM</h3>
                        <select class="form-control" v-model="uom">
                            <option value="" selected="selected">Select UOM</option>
                            <option v-for="u in uom_list" v-bind:value="u.name.toUpperCase()">{{u.name.toUpperCase()}}</option>
                        </select>
                    </div>
                </div>
                <div class="row text-center mt-3 mb-5">
                    <div class="col-6">
                        <button class="button btn btn-secondary btn-block" type="button" @click="submit" :disabled="loading">
                            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                            {{loading ? 'loading' : 'SUBMIT'}}
                        </button>
                    </div>
                    <div class="col-6">
                        <button class="button btn btn-danger btn-block" type="button" @click="clear" :disabled="loading">CLEAR</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "scan_step",
    props: {
        waveId: String,
        taskId: String,
        location: String,
        typeScan: String,
        step: Number,
        groupBy: Array,
        wave: Object,
    },
    data() {
        return {
            scan_result: "",
            camera_title: 'Camera On',
            camera: 'off',
            error: null,
            loading:false,
            show_qty_form: false,
            uom_list: [],
            qty_input: 1,
            uom: '',
            box_id: '',
            lpn: '',
            stateActiveCamera: -1, // 0 box id - 1 lpn
            message: ""
        }
    },
    mounted() {
        console.log('mounted')
        console.log(this.step)
        console.log(this.groupBy)
    },
    created() {
        this.listUom();
    },
    methods: {
        getValueStorage(key) {
          return localStorage.getItem(key);
        },
        onDecode (decodedString) {
            if(this.stateActiveCamera===0) {
                this.box_id = decodedString.trim();
            }else if(this.stateActiveCamera===1) {
                this.lpn = decodedString.trim();
            }
        },
        clickCamera(activeState){
            if(this.camera==='off' && activeState!==this.stateActiveCamera){
                this.camera = 'auto';
                this.camera_title= 'Camera Off';
                this.stateActiveCamera = activeState
            }else if(this.camera==='auto' && activeState===this.stateActiveCamera){
                this.camera = 'off';
                this.camera_title= 'Camera On'
                this.stateActiveCamera = -1;
            }else{
                this.stateActiveCamera = activeState
            }
        },
        async onInitCamera (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        },
        submit() {
            this.message = "";
            if(!this.box_id) {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'BOX ID required',
                    timer: 2000
                });
                return;
            }
            this.loading = true;
            axios.post('save-full-box',{
                'wave_id': this.waveId,
                'box_id': this.box_id,
                'lpn': this.lpn,
                'qty': this.qty_input,
                'uom': this.uom,
                'task_id': this.taskId,
                'location': this.location,
            }).then(res=>{
                this.loading = false;
                this.message = "success";
                var location = encodeURIComponent(this.location);
                window.location=`/counter/stocks/${this.waveId}/${this.taskId}/${location}/item`;
            }).catch(err=>{
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.message,
                    timer: 2000
                });
            })
        },
        clear() {
            this.box_id = '';
            this.lpn = '';
            this.qty_input = 1;
            this.uom = '0';
        },
        listUom() {
            axios.get('uoms').then(res=>{
                this.uom_list = res.data.data.data;
            })
        },
        buildLabel(text) {
            if(text) {
                return text.replace(/_/g," ");
            }else{
                return text;
            }
        }
    }
}
</script>

<style scoped>

</style>
