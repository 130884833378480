<template>
    <div>
        <div class="row pt-4 mb-4">
            <div class="col-12">
                <h3 class="font-weight-bold mb-4 pb-2">TASK COUNTER</h3>
            </div>
            <div class="col-12">
                <nav aria-label="breadcrumb" class="bg-white">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" aria-current="page">
                            <a :href="`/counter/waves`">Home</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            <a :href="`/counter/tasks/${waveId}/list`">{{wave.wave_name}}</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            <a :href="`/counter/stocks/${waveId}/${taskId}/${item}/list`">{{item}}</a>
                        </li>
                        <template v-for="(n,key) in step">
                            <li v-if="key===step-1" class="breadcrumb-item text-uppercase active" aria-current="page" :key="key">
                               Scan {{buildLabel(typeScan)}}
                            </li>
                            <li v-else class="breadcrumb-item" aria-current="page" :key="key">
                                <a :href="`/counter/stocks/item/${waveId}/${taskId}/${item}/${groupBy[key]}/scan/${key+1}`">
                                    {{ getValueStorage(groupBy[key]) }}
                                </a>
                            </li>
                        </template>
                    </ol>
                </nav>
            </div>

        </div>  <!-- ----/row------ -->
        <div class="row">
            <div style="height: 100%">
                <div class="col-12 mt-2 mb-2" v-if="message">
                    <div class="alert alert-success alert-block">
                        <button type="button" class="close" data-dismiss="alert">×</button>
                        <strong>{{ message }}</strong>
                    </div>
                </div>
                <div v-if="show_qty_form===false" class="row">
                    <div class="col-12" v-if="error">
                        <p class="text-danger">{{error}}</p>
                    </div>
                    <div class="col-12">
                        <h5 class="text-center mb-2 mt-4 text-uppercase">Scan {{buildLabel(typeScan)}}</h5>
                        <input type="text" ref="scan_result" name="scan_result"
                               :placeholder="buildLabel(typeScan)"
                               class="form-control"
                               v-model="scan_result"
                               onpaste="return false;"
                               ondrop="return false;"
                               autofocus
                               v-on:keyup.enter="submit"
                        />
                    </div>
                </div>
                <div v-if="show_qty_form" class="row">
                    <div class="col-12">
                        <h5 class="mb-2 mt-4 text-uppercase">QTY</h5>
                        <input type="number" ref="input_qty" class="form-control" v-model="qty_input" v-on:keyup.enter="submit"/>
                        <span v-if="show_qty_data">QTY (OH): {{stock.qty_oh}}</span>
                    </div>
                    <div class="col-12">
                        <h3 class="mb-2 mt-4 text-uppercase">UOM</h3>
                        <select class="form-control" v-model="uom">
                            <option value="" selected="selected">Select UOM</option>
                            <option v-for="u in uom_list" v-bind:value="u.name.toUpperCase()">{{u.name.toUpperCase()}}</option>
                        </select>
                    </div>
                </div>
                <div class="row text-center mt-3 mb-5">
                    <div class="col-6">
                        <button class="button btn btn-secondary btn-block" type="button" @click="submit" :disabled="loading">
                            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                            {{loading ? 'loading' : 'SUBMIT'}}
                        </button>
                    </div>
                    <div class="col-6">
                        <button class="button btn btn-danger btn-block" type="button" @click="clear" :disabled="loading">CLEAR</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import * as Sentry from "@sentry/vue";
var interval;

export default {
    name: "scan_task_item",
    props: {
        waveId: {
            type: String,
            required: true
        },
        taskId: {
            type: String,
            required: true
        },
        item: String,
        typeScan: {
            type: String,
            required: true
        },
        step: {
            type: Number,
            default: 1
        },
        groupBy: Array,
        wave: Object,
        task: Object,
        isNewLine: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            scan_result: "",
            camera_title: 'Camera On',
            camera: 'off',
            error: null,
            loading:false,
            show_qty_form: false,
            uom_list: [],
            qty_input: 1,
            uom: '',
            show_qty_data: false,
            stock: null,
            message: ""
        }
    },
    mounted() {
        this.$refs.scan_result.focus();
        document.addEventListener('keydown', function (evt) {
            if (interval) {
                clearInterval(interval)
            }

            if (evt.code === 'Enter') {
                if (this.scan_result) {
                    this.submit()
                    this.scan_result = '';
                    return;
                }
            }

            if (evt.code !=='Shift') {
                this.scan_result+=evt.key;
                interval = setInterval(() => this.scan_result='', 20)
            }
        })
    },
    created() {
        this.listUom();
    },
    methods: {
        preventPaste(){
            this.scan_result = ""
        },
        getValueStorage(key) {
          return localStorage.getItem(key);
        },
        submit() {
            this.message = "";
            if(this.show_qty_form) {
                if(this.uom==='0') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'UOM required',
                        timer: 2000
                    });
                    return;
                }
                this.loading = true;
                const additionalData = this.groupBy.map(group=>{
                    return {
                        key:group,
                        value: localStorage.getItem(group)
                    };
                })
                axios.post('save-qty-item',{
                    'wave_id': this.waveId,
                    'scan_result': this.scan_result,
                    'qty': this.qty_input,
                    'type': this.typeScan,
                    'uom': this.uom,
                    'task_id': this.taskId,
                    'item': this.item,
                    'additionalData': additionalData
                }).then(res=>{
                    this.message = "success";
                    this.loading = false;
                    this.show_qty_form = false;
                    this.scan_result='';
                    this.qty_input = 1;
                }).catch(err=>{
                    this.loading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response.data.message,
                        timer: 2000
                    });
                    this.clear();
                    Sentry.captureException(err);
                })
            }else{
                if(this.isNewLine) {
                    localStorage.setItem(this.typeScan,this.scan_result);
                    if(this.groupBy.length>this.step && this.groupBy[this.step]!=='qty') {
                        const nextTypeToScan = this.groupBy[this.step];
                        const nextStep = this.step+1;
                        window.location= encodeURI(`/counter/stocks/item/${this.waveId}/${this.taskId}/${this.item}/${nextTypeToScan}/scan/${nextStep}/1`);
                    }else {
                        this.loading = false;
                        // show qty form and UOM
                        this.show_qty_form = true;
                    }
                }else {
                    this.loading = true;
                    var filter = {
                        'wave_id': this.waveId
                    }

                    for(let i=0; i< this.task.group_by.length; i++) {
                        filter[this.task.group_by[i]] = this.task[this.task.group_by[i]];
                    }

                    for (let i=0; i< this.step; i++) {
                        if(i!==this.step-1) {
                            filter[this.groupBy[i]] = localStorage.getItem(this.groupBy[i])
                        }
                    }
                    filter[this.typeScan] = this.scan_result;
                    // validate scan type value to database via api
                    axios.post('stocks',{
                        filter: filter,
                        limit: 1
                    }).then(res=>{
                        if(res.data.data.data.length===0) {
                            this.loading = false;
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: this.typeScan+' not valid',
                                timer: 2000
                            });
                        }else{
                            // proses to next scan
                            // check has next strep for scan
                            localStorage.setItem(this.typeScan,this.scan_result);
                            if(this.groupBy.length>this.step && this.groupBy[this.step]!=='qty') {
                                const nextTypeToScan = this.groupBy[this.step];
                                const nextStep = this.step+1;
                                window.location= encodeURI(`/counter/stocks/item/${this.waveId}/${this.taskId}/${this.item}/${nextTypeToScan}/scan/${nextStep}`);
                            }else {
                                if(this.groupBy[this.step]==='qty') {
                                    this.show_qty_data = true
                                }
                                this.stock = res.data.data.data[res.data.data.data.length-1];
                                this.loading = false;
                                // show qty form and UOM
                                this.show_qty_form = true;
                                this.qty_input = 1;
                            }
                        }
                    }).catch(err=>{
                        this.loading = false;
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.response.data.message,
                            timer: 2000
                        });
                        Sentry.captureException(err);
                        this.clear();
                    })
                }
            }

            if (!this.show_qty_form) {
                this.$refs.scan_result.focus();
            }
        },
        clear() {
            this.scan_result = '';
        },
        listUom() {
            axios.get('uoms').then(res=>{
                this.uom_list = res.data.data.data;
            }).catch(err=>{
                Sentry.captureException(err);
            })
        },
        buildLabel(text) {
            if(text) {
                return text.replace(/_/g," ");
            }else{
                return text;
            }
        }
    }
}
</script>

<style scoped>

</style>
